import React, {useState} from 'react';

import {Backdrop, IconButton, Paper, Typography} from "@mui/material";
import {Close, NavigateBefore, NavigateNext} from "@mui/icons-material";

export interface ImageSliderProps {
  images: Array<{ url: string; caption: string }>
  onClose: () => void;
}

export const ImageSlider = ({images, onClose}: ImageSliderProps) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  return <Backdrop open={true} sx={{background: 'rgba(0,0,0,0.95)', zIndex: (theme) => theme.zIndex.drawer + 1}}>
    <IconButton color="primary" size="large" style={{position: 'fixed', right: 10, top: 10}} onClick={onClose}><Close/></IconButton>
    <img src={images[photoIndex].url} style={{maxWidth: '100%', maxHeight: '80%'}} alt={images[photoIndex].caption}/>
    <IconButton size="large" color="primary"
                style={{position: 'fixed', left: 0, bottom: 2}}
                onClick={() => setPhotoIndex(photoIndex === 0 ? images.length - 1 : photoIndex - 1)}
    >
      <NavigateBefore fontSize="large"/>
    </IconButton>
    <Paper
      style={{
        position: 'fixed',
        bottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
        maxWidth: '70%',
      }}
      elevation={3}
    >
      <Typography style={{
        fontWeight: 'bold',
      }}>{images[photoIndex].caption}</Typography>
    </Paper>
    <IconButton size="large" color="primary"
                style={{position: 'fixed', right: 0, bottom: 2}}
                onClick={() => setPhotoIndex(photoIndex === images.length - 1 ? 0 : photoIndex + 1)}
    >
      <NavigateNext fontSize="large"/>
    </IconButton>
    <Typography color="whitesmoke" style={{position: 'fixed', top: 20, fontWeight: 'bold', fontSize: 25}}>
      Alte Bilder
    </Typography>
  </Backdrop>
};
