import {PageLayout} from "../layout/PageLayout";
import {Button, Paper, Theme} from "@mui/material";
import {sanitize} from "../../utils/sanitize";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../store/UserCredentialStore";
import {makeStyles} from '@mui/styles';
import {useCallback, useState} from "react";
import {PhotoLibraryOutlined} from "@mui/icons-material";
import {ImageSlider} from "./comonents/ImageSlider";
import {Book} from "../../types/Book";
import {LoadingStore} from "../../store/LoadingStore";
import {useEffectOnce} from "@milkscout/react";
import {addToastMessage} from "../../store/MessageStore";
import {getBook} from "../../client";
import {getBookName} from "../../functions/getBookName";
import {ReloadIcon} from "@mui-extra/icons/ReloadIcon";


const useStyle = makeStyles(({palette}: Theme) => ({
  header: {textAlign: 'center'},
  paper: {margin: 10, padding: 20, background: palette.secondary.light + ' !important'}
}));
type BookPageState = {
  status: 'LOADING' | 'ERROR'
} | { status: 'SUCCESS', book: Book }
export const BookPage = () => {
  const {password, username, bookName} = useStore(UserCredentialStore)
  const [state, setState] = useState<BookPageState>({status: 'LOADING'});
  const [slideshowOpen, setSlideshowOpen] = useState(false)
  const classes = useStyle()

  const loadBook = useCallback(async (password: string, username: string) => {
    LoadingStore.set(true)
    setState({status: 'LOADING'})
    try {
      const book = await getBook(username, password, getBookName())
      setState({status: 'SUCCESS', book})
      LoadingStore.set(false)
    } catch (e) {
      addToastMessage('Laden fehlgeschlagen')
      setState({status: 'ERROR'})

      LoadingStore.set(false)
    }

  }, [])

  const handleOpenSlideshow = useCallback(() => {
    gtag('event', 'open_slideshow')
    setSlideshowOpen(true)
  }, [setSlideshowOpen])

  useEffectOnce(() => loadBook(password, username))

  return (
    <PageLayout title={bookName} banner={state.status === 'SUCCESS' ? state.book.banner : ''}
                hideNavigation={slideshowOpen}>
      {state.status === 'SUCCESS' && <>
          <h1 className={classes.header}>{state.book.title}</h1>
          <Button variant="contained"
                  fullWidth
                  startIcon={<PhotoLibraryOutlined/>}
                  style={{marginBottom: 20, fontWeight: 'bold'}}
                  onClick={handleOpenSlideshow}>
              Alte Bilder des Brautpaares
          </Button>
          <Paper elevation={3} className={classes.paper} style={{fontSize: 21, paddingTop: 1, paddingBottom: 1}}>
              <div dangerouslySetInnerHTML={{__html: sanitize(state.book.description || '')}}/>
          </Paper>
        {slideshowOpen && <div>
            <ImageSlider images={state.book.memories || []} onClose={() => setSlideshowOpen(false)}/>
        </div>}
      </>}
      {state.status === 'ERROR' && <>
          <h2 style={{textAlign: 'center', marginTop: 50}}>Die Anfrage ist fehlgeschlagen, bitte versuchen Sie es
              erneut!</h2>
          <Button variant="contained"
                  fullWidth
                  startIcon={<ReloadIcon/>}
                  style={{marginTop: 20, marginBottom: 20, fontWeight: 'bold'}}
                  onClick={() => loadBook(password, username)}>
              Erneut laden
          </Button>
      </>}

      <div style={{marginTop: 80}}/>

    </PageLayout>
  )
}