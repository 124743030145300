import {fetchOrThrow} from "./utils/fetchOrThrow";
import {ImageListResponse} from "./types/ImageListResponse";
import {UserCredential} from "./types/UserCredential";
import {Book} from "./types/Book";
import {GreetingListResponse} from "./types/GreetingListResponse";

export type PresignedUrlResponse = {
  url: string
  key: string
}

export const getHeaders = (credential: UserCredential) => {
  return {
    'x-password': credential.password,
    'x-username': credential.username,
    'x-book': credential.bookId,
  }
}

export const baseUrl = 'https://xz9qh5ky8g.execute-api.eu-central-1.amazonaws.com'

export const listImages = async (credential: UserCredential, cursor: string | undefined): Promise<ImageListResponse> => {
  return await fetchOrThrow(`${baseUrl}/images?cursor=${cursor || ''}`, {
    headers: getHeaders(credential)
  })
}

export const imageUpload = async (credential: UserCredential, file: File, description: string) => {
  const presignedUrlResponse: PresignedUrlResponse = await fetchOrThrow(`${baseUrl}/image-url?type=${encodeURIComponent(file.type)}`, {
    method: 'POST',
    headers: getHeaders(credential),
  })
  await fetch(presignedUrlResponse.url, {
    method: 'PUT',
    body: file,
    headers: {
      'content-type': file.type,
      // 'content-disposition': `attachment; filename=${file.name};`,
    }
  })
  await fetchOrThrow(`${baseUrl}/image?key=${encodeURIComponent(presignedUrlResponse.key)}&type=${encodeURIComponent(file.type)}&description=${encodeURIComponent(description)}&fileSize=${encodeURIComponent(file.size)}`, {
    method: 'POST',
    headers: getHeaders(credential)
  })
}

export const likeImage = async (credential: UserCredential, imageId: string): Promise<void> => {
  await fetchOrThrow(`${baseUrl}/image/like?id=${encodeURIComponent(imageId)}`, {
    method: 'POST',
    headers: getHeaders(credential)
  })
}

export const getBook = async (username: string, password: string, bookName: string): Promise<Book> => {
  return await fetchOrThrow(`${baseUrl}/book`, {
    headers: {
      'x-password': password,
      'x-username': username,
      'x-book': bookName,
    }
  })

}
export const listGreeting = async (credential: UserCredential, cursor: string | undefined): Promise<GreetingListResponse> => {
  return await fetchOrThrow(`${baseUrl}/greetings?cursor=${cursor || ''}`, {
    headers: getHeaders(credential)
  })
}

export const addGreeting = async (credential: UserCredential, greeting: string): Promise<void> => {
  await fetchOrThrow(`${baseUrl}/greeting`, {
    method: 'POST',
    headers: getHeaders(credential),
    body: JSON.stringify({text: greeting}),
  })
}
export const likeGreeting = async (credential: UserCredential, greetingId: string): Promise<void> => {
  await fetch(`${baseUrl}/greeting/like?id=${encodeURIComponent(greetingId)}`, {
    method: 'POST',
    headers: getHeaders(credential)
  })
}

