import React, {PropsWithChildren} from 'react';
import {Button} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

export interface LoadMoreButtonProps {
  onLoadMoreClick: () => void
}

export const LoadMoreButton = ({children, onLoadMoreClick}: PropsWithChildren<LoadMoreButtonProps>) => {
  return (<div style={{marginBottom: 10, marginTop: 10}}>
    <Button startIcon={<ExpandMore/>}
            size="large"
            color="primary"
            style={{fontWeight: 'bold'}}
            onClick={onLoadMoreClick}>
      {children}
    </Button>
  </div>);
};
