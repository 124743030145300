import {atom} from "nanostores";
import {ToastMessage} from "../types/ToastMessage";
import {getRandomId} from "@milkscout/react";


export const MessageStore = atom<Array<ToastMessage>>([])

export const removeToastMessage = (message: ToastMessage) => {
  MessageStore.set(MessageStore.get().filter((m) => m.id !== message.id));
}

export const addToastMessage = (message: string) => {
  MessageStore.set([...MessageStore.get(), {id: getRandomId(), message}]);
}