import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AppRoutes} from "./router/AppRoutes";
import {ThemeProvider} from "./theme/ThemeProvider";


export const App = () => {

  return <ThemeProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de-DE">
      <AppRoutes/>
    </LocalizationProvider>
  </ThemeProvider>
}
