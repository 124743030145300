import {PageLayout} from "../layout/PageLayout";
import React, {useCallback, useState} from "react";
import {Backdrop, CircularProgress, Fab, Paper, Typography, useTheme} from "@mui/material";
import ImageUploading, {ImageListType} from "react-images-uploading";
import {AddPhotoAlternate as AddImageIcon, UploadFile} from "@mui/icons-material";
import {ImageUploadContainer} from "./components/ImageUploadContainer";
import {useNavigate} from "react-router-dom";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../store/UserCredentialStore";
import {imageUpload} from "../../client";
import {UploadImage} from "../../types/UploadImage";
import {addToastMessage} from "../../store/MessageStore";
import {IMAGES_ROUTE} from "../../router/routes";


export const ImageUploadPage = () => {
  const navigate = useNavigate()
  const credential = useStore(UserCredentialStore);
  const {bookName} = credential;

  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState<Array<UploadImage>>([])
  const maxNumber = 15
  const theme = useTheme()
  const [filesUploaded, setFilesUploaded] = useState(0)

  const onChange = (
    imageList: ImageListType
  ) => {
    // data for submit
    setImages(imageList as never[]);
  };

  const onDescriptionUpdate = useCallback((description: string, index: number) => {
    const newImages = [...images];
    newImages[index].description = description;
    setImages(newImages);
  }, [setImages, images])

  const onUploadClick = useCallback(async () => {
    setLoading(true)
    try {
      let index = 1
      for (const image of images) {
        if (image.file) {
          await imageUpload(credential, image.file, image?.description || '')
          setFilesUploaded(index)
          addToastMessage(`Datei ${index++} wurde erfolgreich hochgeladen`)
        }
      }
      setImages([])
      gtag('event', 'image_uploaded')
      navigate(IMAGES_ROUTE)
    } catch (e) {
      addToastMessage('Upload fehlgeschlagen')
      gtag('event', 'image_upload_failed')
    } finally {
      setLoading(false)
    }
  }, [images, setLoading, setImages, navigate, credential, setFilesUploaded])

  return <PageLayout title={bookName}>
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={loading || false}
    >
      <Paper elevation={1} style={{background: theme.palette.secondary.light, padding: 20}}>
        <div style={{width: '100%', display: 'flex'}}>
          <CircularProgress style={{margin: 'auto', color: 'black'}} color="inherit"/>
        </div>
        <Typography align="center" marginTop={2}>Upload gestartet</Typography>
        <Typography align="center">{filesUploaded}/{images.length} Dateien hochgeladen</Typography>
      </Paper>
    </Backdrop>
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      acceptType={["jpg", "png", "mp4", "mkv", "mpg", "ogg", 'jpeg', 'avi']}
      allowNonImageType={true}
      maxNumber={maxNumber}
    >
      {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <div
            style={{
              color: isDragging ? 'red' : 'inherit',
              width: '100%',
              border: 'dotted 4px',
              cursor: 'pointer',
              borderColor: isDragging ? 'red' : 'black',
              marginBottom: 30,
            }}
            onClick={onImageUpload}
            {...dragProps}
          >
            <div style={{margin: 30, textAlign: 'center'}}>
              <div>
                <AddImageIcon fontSize={"large"}/>
              </div>
              <div>
                Klicken oder Bilder einfügen
              </div>
            </div>
          </div>
          {imageList.length > 0 && <>
              <Fab variant="extended" color="primary" aria-label="add" onClick={onUploadClick}
                   style={{
                     position: 'fixed',
                     right: 20,
                     bottom: 75,
                     background: theme.palette.primary.main,
                     fontWeight: 'bold',
                   }}>
                  <UploadFile style={{marginRight: 3}}/>
                {imageList.length > 1 ? ` ${imageList.length} Bilder hochladen` : 'Bild hochladen'}
              </Fab>
          </>}
          {imageList.map((image, index) => (
            <ImageUploadContainer key={'upload-image-' + index} image={image}
                                  onRemoveClick={() => onImageRemove(index)}
                                  onDescriptionUpdate={(desc) => onDescriptionUpdate(desc, index)}/>
          ))}
        </div>
      )}
    </ImageUploading>
    <div style={{marginTop: 110}}/>
  </PageLayout>
}