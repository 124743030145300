import {PageLayout} from "../layout/PageLayout";
import React, {useCallback, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import {Button, Fab, Paper, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Edit, Preview, Save} from "@mui/icons-material";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../store/UserCredentialStore";
import {addGreeting} from "../../client";
import {addToastMessage} from "../../store/MessageStore";
import {LoadingStore} from "../../store/LoadingStore";
import {GREETINGS_ROUTE} from "../../router/routes";
import {sanitize} from "../../utils/sanitize";

export const CreateGreetingPage = () => {
  const credential = useStore(UserCredentialStore);
  const theme = useTheme();
  const loading = useStore(LoadingStore);
  const [previewOpen, setPreviewOpen] = useState(false)

  const navigate = useNavigate()
  const [text, setText] = useState('');

  const save = useCallback(async () => {
    if (!text) {
      addToastMessage('Kein Text vorhanden')
      return
    }
    LoadingStore.set(true)
    try {
      await addGreeting(credential, text)
      addToastMessage('Erfolgreich gespeichert')
      gtag('event', 'greeting_saved')
      navigate(GREETINGS_ROUTE)
    } catch (e) {
      console.error('failed', e)
      addToastMessage('Speichern fehlgeschlagen')
      gtag('event', 'save_greeting_failed')
    } finally {
      LoadingStore.set(false)
    }
  }, [text, credential, navigate])

  return (
    <PageLayout title="Grußtext schreiben" showRing={false}>
      {!previewOpen && <ReactQuill theme="snow" value={text} onChange={setText} style={{height: '70%', fontSize: 22}}/>}
      {previewOpen && <>
          <h1>Vorschau</h1>
          <Paper elevation={8} style={{
            marginTop: 15,
            paddingLeft: 6,
            paddingRight: 6,
            fontSize: 22,
          }}>
              <div style={{fontSize: 25}} dangerouslySetInnerHTML={{__html: sanitize(text)}}/>
          </Paper>
      </>}
      {(!previewOpen && !!text) && <>
          <Button color="primary"
                  variant="contained"
                  aria-label="add"
                  startIcon={<Preview/>}
                  style={{marginTop: 15, fontWeight: 'bold'}}
                  onClick={() => setPreviewOpen(true)}
                  disabled={loading}
          >
              Vorschau anzeigen
          </Button>
      </>}
      {previewOpen && <>
          <Fab variant="extended" color="secondary" aria-label="add"
               onClick={() => setPreviewOpen(false)}
               disabled={loading}
               style={{
                 position: 'fixed',
                 right: 20,
                 bottom: 135,
                 background: theme.palette.secondary.main,
                 fontWeight: 'bold',
               }}>
              <Edit style={{marginRight: 3}}/>
              Gruß ändern
          </Fab>
          <Fab variant="extended" color="primary" aria-label="add" onClick={save} disabled={loading}
               style={{
                 position: 'fixed',
                 right: 20,
                 bottom: 75,
                 background: theme.palette.primary.main,
                 fontWeight: 'bold',
               }}>
              <Save style={{marginRight: 3}}/>
              Gruß speichern
          </Fab>
      </>
      }
      <div style={{marginTop: previewOpen ? 200 : 80}}/>
    </PageLayout>
  )
}