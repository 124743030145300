import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    gtag('event', 'page_view', {value: location.pathname})
  }, [location]);
};

export default usePageTracking;