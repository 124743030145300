import React, {useCallback, useState} from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../../store/UserCredentialStore";
import {removeUserCredential} from "../../../router/LoginGuard";
import {BOOK_ROUTE, GREETINGS_ROUTE, IMAGES_ROUTE, REGISTER_ROUTE} from "../../../router/routes";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {
  Image as ImageIcon,
  Info as InfoIcon,
  Logout,
  MenuBook as MenuBookIcon,
  Message,
  MoreVert
} from "@mui/icons-material";
import {ImpressDialog} from "./ImpressDialog";

const useStyle = makeStyles(({palette}: Theme) => ({
  navRoot: {position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000},
  navNavigation: {background: `${palette.secondary.light} !important`}
}));

export const Navigation = () => {
  const theme = useTheme();
  const credential = useStore(UserCredentialStore);
  const classes = useStyle();

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [openImpress, setOpenImpress] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleLogout = () => {
    removeUserCredential();
    UserCredentialStore.set(undefined as any)
    gtag('event', 'logout')
    gtag('set', 'username', 'not_set')
    navigate(REGISTER_ROUTE)
  }
  const handleOpenImpress = useCallback(() => {
    gtag('event', 'open_impress')
    setOpenImpress(true)
  }, [setOpenImpress])
  return (<>
    <Drawer open={showDrawer} onClose={() => setShowDrawer(false)} anchor="bottom">
      <Box
        sx={{width: 'auto'}}
        role="presentation"
        onClick={() => setShowDrawer(false)}
      >
        <List>
          <ListItem disablePadding onClick={handleOpenImpress}>
            <ListItemButton>
              <ListItemIcon>
                <InfoIcon/>
              </ListItemIcon>
              <ListItemText>
                <Typography style={{fontWeight: 'bold', color: theme.palette.grey["700"]}}>Impressum</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider/>
          {credential && <ListItem disablePadding onClick={handleLogout}>
              <ListItemButton>
                  <ListItemIcon>
                      <Logout/>
                  </ListItemIcon>
                  <ListItemText>
                      <Typography style={{fontWeight: 'bold', color: theme.palette.grey["700"]}}>Ausloggen</Typography>
                  </ListItemText>
              </ListItemButton>
          </ListItem>}
        </List>
      </Box>
    </Drawer>
    <ImpressDialog open={openImpress} onClose={() => setOpenImpress(false)}/>
    {typeof credential?.username !== 'undefined' &&
        <Paper className={classes.navRoot} elevation={3}>
            <BottomNavigation showLabels className={classes.navNavigation}>
                <BottomNavigationAction onClick={() => navigate(BOOK_ROUTE)} label="Buch" icon={<MenuBookIcon/>}
                                        value="Book"/>
                <BottomNavigationAction onClick={() => navigate(IMAGES_ROUTE)} label="Bilder" icon={<ImageIcon/>}
                                        value="Images"/>
                <BottomNavigationAction onClick={() => navigate(GREETINGS_ROUTE)} label="Grüße" icon={<Message/>}
                                        value="Greetings"/>
                <BottomNavigationAction onClick={() => setShowDrawer(true)}
                                        label="Einstellungen" icon={<MoreVert/>}
                                        value="More"/>
            </BottomNavigation>
        </Paper>}
  </>);
};
