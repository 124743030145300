import React from 'react';
import {Favorite} from "@mui/icons-material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {Button} from "@mui/material";

export interface LikeButtonProps {
  count: number;
  liked: boolean
  onLikeClick: () => void;
}

export const LikeButton = ({count, liked, onLikeClick}: LikeButtonProps) => {
  return (<Button color="inherit"
                  startIcon={liked ? <Favorite style={{color: 'red'}}/> : <FavoriteBorderIcon style={{color: 'red'}}/>}
                  style={{fontWeight: 'bold'}}
                  onClick={onLikeClick}>
    {count} likes
  </Button>);
};
