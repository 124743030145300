import {PropsWithChildren} from "react";
import {AppBar, Container, Toolbar, Typography} from "@mui/material";
import {RingIcon} from "@mui-extra/icons/RingIcon";
import {LoadingBanner} from "./components/LoadingBanner";
import {Navigation} from "./components/Navigation";
import {MessageSnackbar} from "./components/MessageSnackbar";
import usePageTracking from "../../utils/usePageTracking";


export type PageLayoutProps = {
  title: string
  banner?: string
  hideNavigation?: boolean
  showRing?: boolean
} & PropsWithChildren

export const PageLayout = ({title, banner, children, hideNavigation, showRing}: PageLayoutProps) => {
  usePageTracking()
  return (<>
      <AppBar position="relative" color="secondary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1, textAlign: 'center', fontWeight: 'bold'}}>
            <span>
              {title}{showRing !== false && <>&nbsp;<RingIcon style={{marginBottom: -3}}/></>}
            </span>
          </Typography>
        </Toolbar>
      </AppBar>
      {banner && <>
          <div style={{
            height: 250,
            background: `url(${banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'auto 100%',
          }}/>
      </>}
      <Container style={{maxWidth: 800}}>
        <div style={{marginTop: banner ? -10 : 20, marginBottom: 70}}>
          <LoadingBanner/>
          {children}
          <MessageSnackbar/>
        </div>
      </Container>
      {!hideNavigation && <Navigation/>}
    </>
  );
}