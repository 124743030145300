import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {
  BOOK_ROUTE,
  GREETINGS_NEW_ROUTE,
  GREETINGS_ROUTE,
  IMAGES_NEW_ROUTE,
  IMAGES_ROUTE,
  REGISTER_ROUTE
} from "./routes";
import {LoginGuard} from "./LoginGuard";
import {BookPage} from "../page/book/BookPage";
import {ImageListPage} from "../page/image/ImageListPage";
import {CreateGreetingPage} from "../page/greeting/CreateGreetingPage";
import {RegisterPage} from "../page/register/RegisterPage";
import {GreetingListPage} from "../page/greeting/GreetingListPage";
import {ImageUploadPage} from "../page/image/ImageUploadPage";

export const AppRoutes = () => {
  return (<BrowserRouter>
      <Routes>
        <Route element={<LoginGuard/>}>
          <Route path={BOOK_ROUTE} element={<BookPage/>}/>
          <Route path={IMAGES_ROUTE} element={<ImageListPage/>}/>
          <Route path={IMAGES_NEW_ROUTE} element={<ImageUploadPage/>}/>
          <Route path={GREETINGS_ROUTE} element={<GreetingListPage/>}/>
          <Route path={GREETINGS_NEW_ROUTE} element={<CreateGreetingPage/>}/>
        </Route>
        <Route path={REGISTER_ROUTE} element={<RegisterPage/>}/>
        <Route path="*" element={<Navigate to={BOOK_ROUTE}/>}/>
      </Routes>
    </BrowserRouter>
  );
};
