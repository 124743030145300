import React from 'react';
import {useStore} from "@nanostores/react";
import {MessageStore, removeToastMessage} from "../../../store/MessageStore";
import {Snackbar} from "@mui/material";
import {UserCredentialStore} from "../../../store/UserCredentialStore";

export const MessageSnackbar = () => {
  const messages = useStore(MessageStore)
  const credential = useStore(UserCredentialStore)
  return (<>
    {messages.map(m => (
      <Snackbar key={'snack-id-' + m.id}
                open={!!m}
                style={{marginBottom: typeof credential?.username !== "undefined" ? 60 : 0}}
                autoHideDuration={4000}
                message={m.message}
                onClose={() => removeToastMessage(m)}
      />))}
  </>);
};
