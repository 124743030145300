import React, {PropsWithChildren} from 'react';
import {LocalStorage} from "@milkscout/react";
import {REGISTER_ROUTE} from "./routes";
import {Navigate, Outlet} from "react-router-dom";
import {UserCredentialStore} from "../store/UserCredentialStore";
import {UserCredential} from "../types/UserCredential";


export const USER_CREDENTIALS_VERSION = 1
const USER_CREDENTIALS_LOCAL_STORAGE_KEY = 'user-credentials';

export const getUserCredential = (): UserCredential | undefined => {
  const rawCredentials = LocalStorage.getItem(USER_CREDENTIALS_LOCAL_STORAGE_KEY);
  if (rawCredentials) {
    const credentials = JSON.parse(rawCredentials) as UserCredential
    if (credentials.version === USER_CREDENTIALS_VERSION) {
      return credentials
    }
  }
};
export const setUserCredential = (credentials: UserCredential): void => {
  LocalStorage.setItem(USER_CREDENTIALS_LOCAL_STORAGE_KEY, JSON.stringify(credentials));
};

export const removeUserCredential = (): void => {
  LocalStorage.removeItem(USER_CREDENTIALS_LOCAL_STORAGE_KEY);
};

export const LoginGuard = ({children = <Outlet/>}: PropsWithChildren<unknown>) => {
  const credentials = getUserCredential();
  UserCredentialStore.set(credentials as any);
  if (!credentials) {
    return <Navigate to={REGISTER_ROUTE}/>
  }

  return (<>
    {children}
  </>);
};
