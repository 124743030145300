import React from 'react';
import {Backdrop, CircularProgress, Theme} from "@mui/material";
import {LoadingStore} from "../../../store/LoadingStore";
import {useStore} from "@nanostores/react";
import {makeStyles} from '@mui/styles';

const useStyle = makeStyles(({zIndex}: Theme) => ({
  root: {color: '#fff', zIndex: zIndex.drawer + 1}
}));

export const LoadingBanner = () => {
  const loading = useStore(LoadingStore);
  const classes = useStyle();
  return (<Backdrop
    className={classes.root}
    open={loading}
  >
    <CircularProgress color="inherit"/>
  </Backdrop>);
};
