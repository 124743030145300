import React from 'react';
import {Button, Card, CardContent, CardHeader, CardMedia, Stack, TextField, Typography, useTheme} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {UploadImage} from "../../../types/UploadImage";

export interface ImageContainerProps {
  image: UploadImage
  onRemoveClick: () => void
  onDescriptionUpdate: (desc: string) => void
}

export const ImageUploadContainer = ({image, onRemoveClick, onDescriptionUpdate}: ImageContainerProps) => {
  const theme = useTheme()
  return (<Card elevation={3} color="secondary" style={{marginBottom: 15}}>
    <CardHeader title={<Typography style={{fontWeight: 'bold'}}>{image.file?.name}</Typography>}></CardHeader>
    {image.file?.type.startsWith('image/') && <CardMedia component="img" src={image.dataURL!} alt={""}/>}
    {image.file?.type.startsWith('video/') && <CardContent>
        <video width="100%" loop autoPlay={false} controls>
            <source src={image.dataURL} type={image.file?.type}/>
        </video>
    </CardContent>}
    <CardContent>
      <Stack spacing={2}>
        <TextField fullWidth label={'Bildbeschreibung'} value={image.description || ''}
                   onChange={(evt) => onDescriptionUpdate(evt.target.value)}/>
        <div style={{display: 'flex'}}>
          <Button variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon style={{color: theme.palette.error.light}}/>}
                  style={{fontWeight: 'bold'}}
                  onClick={onRemoveClick}>
            Entfernen
          </Button>
          {!!image.file?.size &&
              <Typography style={{fontWeight: 'bold', flex: '1', textAlign: 'right', marginTop: 5}}>
                {Math.round(image.file?.size / 10_000) / 100} MB
              </Typography>
          }
        </div>
      </Stack>
    </CardContent>
  </Card>);
};
